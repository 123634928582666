export const QuestionsList = [
    {
        id: 1,
        question: "How does the free trial work?",
        answer: "When you sign-up for Govbuddy’s 30-day trial you’ll gain access to most its features free of charge on our Starter plan. This means you can set up jobs, create invoices and schedule jobs, as well as access our product tour and training tools. The only limitations to what you don’t get in trial versus what we offer paying customers is the number of jobs you can create is restricted to 30 and SMS functionality is disabled.",
        show: true,
    },
    {
        id: 2,
        question: "Is there a minimum amount of users for the Individual or the Team plan?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", 
        show: false,
    },
    {
        id: 3,
        question: "Do you offer a monthly or an annual billing?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", 
        show: false,
    },
    {
        id: 4,
        question: "How does the free trial work?",
        answer: "When you sign-up for Govbuddy’s 30-day trial you’ll gain access to most its features free of charge on our Starter plan. This means you can set up jobs, create invoices and schedule jobs, as well as access our product tour and training tools. The only limitations to what you don’t get in trial versus what we offer paying customers is the number of jobs you can create is restricted to 30 and SMS functionality is disabled.",
        show: false,
    },
    {
        id: 5,
        question: "Is there a minimum amount of users for the Individual or the Team plan?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", 
        show: false,
    },
    {
        id: 6,
        question: "Do you offer a monthly or an annual billing?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.", 
        show: false,
    },
]